// Registration action types
export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const RESET_REGISTRATION_STATE = "RESET_REGISTRATION_STATE";
export const SET_IS_REGISTERING = "SET_IS_REGISTERING";

// Authentication action types
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

// Logout action types
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

// User actions types
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_FAILURE = "FETCH_USER_DATA_FAILURE";
export const DEACTIVATE_USER_REQUEST = "DEACTIVATE_USER_REQUEST";
export const DEACTIVATE_USER_SUCCESS = "DEACTIVATE_USER_SUCCESS";
export const DEACTIVATE_USER_FAILURE = "DEACTIVATE_USER_FAILURE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

// Linkup actions types
export const SET_IS_LOADING = "SET_IS_LOADING";
export const UPDATE_LINKUP_SUCCESS = "UPDATE_LINKUP_SUCCESS";
export const UPDATE_LINKUP_FAILURE = "UPDATE_LINKUP_FAILURE";
export const FETCH_LINKUPS_SUCCESS = "FETCH_LINKUPS_SUCCESS";
export const FETCH_LINKUPS_FAILURE = "FETCH_LINKUPS_FAILURE";
export const UPDATE_LINKUP_LIST = "UPDATE_LINKUP_LIST";
export const MARK_LINKUPS_AS_EXPIRED_SUCCESS =
  "MARK_LINKUPS_AS_EXPIRED_SUCCESS";
export const SHOW_NEW_LINKUP_BUTTON = "SHOW_NEW_LINKUP_BUTTON";

// Editing linkup actions types
export const SET_EDITING_LINKUP = "SET_EDITING_LINKUP";
export const CLEAR_EDITING_LINKUP = "CLEAR_EDITING_LINKUP";

// Conversations action types
export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const SET_SELECTED_CONVERSATION = "SET_SELECTED_CONVERSATION";
export const SET_MESSAGES = "SET_MESSAGES";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION";
export const INCREMENT_UNREAD_COUNT = "INCREMENT_UNREAD_COUNT";
export const DECREMENT_UNREAD_COUNT = "DECREMENT_UNREAD_COUNT";
export const RESET_UNREAD_COUNT = "RESET_UNREAD_COUNT";

// Messaging action types
export const SET_UNREAD_MESSAGES_COUNT = "SET_UNREAD_MESSAGES_COUNT";
export const INCREMENT_UNREAD_MESSAGES_COUNT =
  "INCREMENT_UNREAD_MESSAGES_COUNT";
export const MARK_MESSAGES_AS_READ = "MARK_MESSAGES_AS_READ";

// Notifications action types
export const FETCH_UNREAD_NOTIFICATIONS_COUNT =
  "FETCH_UNREAD_NOTIFICATIONS_COUNT";
export const UPDATE_UNREAD_NOTIFICATIONS_COUNT =
  "UPDATE_UNREAD_NOTIFICATIONS_COUNT";
export const INCREMENT_UNREAD_NOTIFICATIONS_COUNT =
  "INCREMENT_UNREAD_NOTIFICATIONS_COUNT";
export const DECREMENT_UNREAD_NOTIFICATIONS_COUNT =
  "DECREMENT_UNREAD_NOTIFICATIONS_COUNT";

// User Sent Requests types
export const ADD_SENT_REQUEST = "ADD_SENT_REQUEST";
export const FETCH_LINKUP_REQUESTS_SUCCESS = "FETCH_LINKUP_REQUESTS_SUCCESS";
export const FETCH_LINKUP_REQUESTS_ERROR = "FETCH_LINKUP_REQUESTS_ERROR";
